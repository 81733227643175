<template>
  <div class="roomPrice">
      
    <iframe scrolling: no
      :src="src"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import urls from "../../../utils/baseUrl2.js";
export default {
  name: "seckillPrice",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      src: `${urls.baseUrl}seckillPrice/roomPriceIframe.html?roomId=${this.id}&isDevelopment=${urls.baseUrl}`,
    };
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>
<style scoped lang='less'>
.roomPrice {
  height: 860px;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>